@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSansExpanded";
  src: url("../fonts/EncodeSansExpanded-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MuseoModerno";
  src: url("../fonts/MuseoModerno-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MuseoModerno";
  src: url("../fonts/MuseoModerno-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black: #383C43;
  --color-text: #566277;
  --color-violet: #280643;
  --color-primary:#D98C72;
  --color-primary-second:#824A64;
  --color-pink:#CDBDDA;
  --color-green:#169f7b;
  --color-contact-grey:#ABACBA;
  --color-text-gradient:linear-gradient(90deg, #FFA68F 2.12%, #FFB2FB 51.92%, #D9A9FF 90.26%);
  --Montserrat: 'Montserrat', sans-serif;
  --MM: 'MuseoModerno', sans-serif;
  --ESE: 'EncodeSansExpanded', sans-serif;
}

*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

mark {
  -webkit-text-fill-color: initial;
  text-fill-color: transparent;
  background: none;
}

.img {
  display: block;
}

.img.contain img {
  -o-object-fit: contain;
  object-fit: contain;
}

.img > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

button {
  background: transparent;
}

h1 {
  margin: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 9.6px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 9.1px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 8.53px;
  }
}

@media only screen and (max-width: 1170px) {
  html {
    font-size: 7.8px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 6.83px;
  }
}

@media only screen and (max-width: 890px) {
  html {
    font-size: 5.93px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 5.12px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
